<template>
  <nav class="flex h-full min-h-0 flex-col">
    <div class="flex flex-1 flex-col overflow-y-auto p-4 [&>[data-slot=section]+[data-slot=section]]:mt-8">
      <div data-slot="section" class="flex flex-col gap-0.5">
        <AppSidebarItem v-for="item in mainNav" :type="item.type" :to="item?.to" :label="item?.label" :icon="item?.icon" iconSize="large" :function="item?.function" />
      </div>
      <div v-if="user" data-slot="section" class="flex flex-col gap-0.5">
        <h3 class="nba mb-1 px-2 text-xs/6 font-medium text-zinc-400">Play</h3>
        <AppSidebarItem v-for="item in links" :type="item.type" :to="item?.to" :label="item?.label" :icon="item?.icon" :function="item?.function" />
      </div>
      <div aria-hidden="true" class="mt-8 flex-1 lg:hidden"></div>
      <div data-slot="section" class="flex flex-col gap-0.5 lg:hidden">
        <AppSidebarItem v-for="item in supportItems" :type="item.type" :to="item?.to" :label="item?.label" :icon="item?.icon" :function="item?.function" />
      </div>
    </div>
  </nav>
</template>

<script setup>
const user = useSupabaseUser()

let loginLink = []
if (!user.value) {
  loginLink = [
    {
      type: 'link',
      to: '/login',
      label: 'Login',
      icon: 'heroicons:user-20-solid',
    },
    {
      type: 'link',
      to: '/join',
      label: 'Get started',
      icon: 'heroicons:arrow-long-right-20-solid',
    }
  ]
}

const mainNav = [
  {
    type: 'link',
    to: '/',
    label: 'Home',
    icon: 'heroicons:home-20-solid',
  },
  {
    type: 'link',
    to: '/blog',
    label: 'Blog',
    icon: 'heroicons:newspaper-20-solid',
  },
  {
    type: 'link',
    to: '/about',
    label: 'About',
    icon: 'heroicons:globe-asia-australia-20-solid',
  },
  ...loginLink
]

const links = [
  {
    type: 'link',
    to: '/dashboard',
    label: 'Dashboard'
  },
  {
    type: 'link',
    to: '/collections',
    label: 'Collections'
  },
  {
    type: 'link',
    to: '/explore',
    label: 'Explore'
  },
  {
    type: 'link',
    to: '/favorites',
    label: 'Favorites'
  },
  {
    type: 'link',
    to: '/notifications',
    label: 'Notifications'
  }
]

const supportItems = [
  {
    type: 'link',
    to: '/contact',
    label: 'Contact',
    icon: 'heroicons:chat-bubble-oval-left-20-solid',
  }
]

</script>